import request from '@/utils/request';

// 获取配置文件
export function getProductsConfig() {
  return request({
    url: '/transaction/product/config',
    responseType: 'ProductValidateConfigResp',
  });
}

// 商品列表
export async function getGoodsList(params = {}) {
  const req = request.create('GoodsSearchReq', params);
  return await request({
    url: '/transaction/product/goods/query/search',
    requestBody: req,
    responseType: 'GoodsSearchRes',
  });
}
// 商品详情
export async function getGoodsDetail(params = {}) {
  const req = request.create('GoodsDetailReq', params);
  return await request({
    url: '/transaction/product/goods/query/detail',
    requestBody: req,
    responseType: 'GoodsDetail',
  });
}

// 产品详情
export async function getProductsDetail(params = {}) {
  const req = request.create('ProductDetailReq', params);
  return await request({
    url: '/transaction/product/query/detail',
    requestBody: req,
    responseType: 'ProductDetail',
  });
}

// 获取搜索关键字
export async function getProductTags(params = {}) {
  const req = request.create('GoodsTagsListReq', params);
  return await request({
    url: '/transaction/product/tags/list',
    requestBody: req,
    responseType: 'GoodsTagsListRes',
  });
}

// 消息助手消息列表
export async function getMarketPlaceMessage(params = {}) {
  const req = request.create('TaskAssistantHistoryReq', params);
  return await request({
    url: '/notification/markerPlace/message/history',
    requestBody: req,
    responseType: 'MarketPlaceMessageResponse',
  });
}
// 是否存在机构上架商品
export async function getExistInternal() {
  return await request({
    url: '/transaction/product/goods/query/existInternal',
    responseType: 'ExistInternalRes',
  });
}
