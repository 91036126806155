import request from '@/utils/request';

// 资讯平台获取定义
export async function getNewsClassifications(params = {}) {
  const req = request.create('ClassificationsRequest', params);
  return await request({
    url: '/news/pf/classifications',
    requestBody: req,
    responseType: 'Classifications',
  });
}

// get news list
export async function getNewsList(params = {}) {
  const req = request.create('NewsSearch', params);
  return await request({
    url: '/miniprogram/getNewsList',
    requestBody: req,
    responseType: 'NewsListResponse',
  });
}


// get news details for news platform
export async function getNewsDetails(params, isLogin) {
  const req = request.create('NewsDetailReq', params);
  const url = isLogin ? '/news/pf/detail' : '/news/withOutSignature/view';
  return request({
    url,
    requestBody: req,
    responseType: 'NewsPlatformDetail',
  });
}

// get fav news list
export async function getFavNewsList(params = {}) {
  const req = request.create('NewsSearch', params);
  return await request({
    url: '/news/collect/list',
    requestBody: req,
    responseType: 'NewsListResponse',
  });
}
export async function getPFFavNewsList(params = {}) {
  const req = request.create('NewsPlatformSearch', params);
  return await request({
    url: '/news/pf/collect/list',
    requestBody: req,
    responseType: 'NewsListResponse',
  });
}

// fav news
export async function favNews(params = {}) {
  const req = request.create('NewsDetailReq', params);
  return await request({
    url: '/news/collect',
    requestBody: req,
  });
}

// cancel fav news
export async function deleteFavNews(params = {}) {
  const req = request.create('NewsDetailReq', params);
  return await request({
    url: '/news/cancel/collect',
    requestBody: req,
  });
}


// search news platform list
export function searchNewsPFList(params = {}, isLogin) {
  const req = request.create('NewsPlatformSearch', params);
  const url = isLogin ? '/news/pf/search' : '/news/withOutSignature/search';
  return request({
    url,
    requestBody: req,
    responseType: 'NewsListResponse',
  });
}


// search related news products
export async function searchRelatedNewsProducts(params = {}) {
  const req = request.create('dp.BestPriceRalatedReq', params);
  return await request({
    url: '/dataplatform/quotation/best_price',
    requestBody: req,
    responseType: 'dp.QuotationSearchRsp',
  });
}
